// --------

import { Timestamp } from "firebase/firestore";

export const getItemsFromQuerySnapshot = querySnapshot => {
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export const getItemFromQuerySnapshot = querySnapshot => {
    return { ...querySnapshot.data(), id: querySnapshot.id };
}

export const getItemsFromDatabaseQuerySnapshot = querySnapshot => {
    const items = []
    if (querySnapshot) {
        querySnapshot.forEach(doc => {
            const item = doc.val()
            // checik if item is an object
            if (item) {
                item.id = doc.key
                items.push(item)
            }
        })
    }
    return items;
}

export const getItemFromDatabaseSnapshot = querySnapshot => {
    if (querySnapshot) {
        const item = querySnapshot.val()
        if (item) {
            item.id = querySnapshot.key
        }
        return item;
    }
    return null;
}

export const getTimestampFromDate = (date) => {
    // check if date is a string
    if (typeof date === 'string') {
        return Timestamp.fromDate(new Date(date));
    }
    return date;
}