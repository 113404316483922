export const appConfig = {
    title: 'HCP Down'
}

// Colores obtenidos de https://www.w3schools.com/cssref/css_colors.asp
export const scoreData = [
    { score: -4, name: 'Condor', style: { backgroundColor: 'green', color: 'white' }, css: 'hole-shot-albatros' },
    { score: -3, name: 'Albatros', style: { backgroundColor: 'blue', color: 'white' }, css: 'hole-shot-albatros' },
    { score: -2, name: 'Eagle', style: { backgroundColor: '#5fafff', color: 'white' }, css: 'hole-shot-eagle' },
    { score: -1, name: 'Birdie', style: { backgroundColor: '#50ffff', color: 'black' }, css: 'hole-shot-birdie' },
    { score: 0, name: 'PAR', style: { backgroundColor: '#f0f0f0', color: 'black' }, css: 'hole-shot-par' },
    { score: 1, name: 'Bogey', style: { backgroundColor: 'orange', color: 'white' }, css: 'hole-shot-bogey' },
    { score: 2, name: '2Bogey', style: { backgroundColor: 'red', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 3, name: '3Bogey', style: { backgroundColor: 'darkred', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 4, name: '4Bogey', style: { backgroundColor: 'darkred', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 5, name: '5Bogey', style: { backgroundColor: 'brown', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 6, name: '6Bogey', style: { backgroundColor: 'brown', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 7, name: '7Bogey', style: { backgroundColor: 'brown', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 8, name: '8Bogey', style: { backgroundColor: 'brown', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 9, name: '9Bogey', style: { backgroundColor: 'gray', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 10, name: '10Bogey', style: { backgroundColor: 'gray', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 11, name: '11Bogey', style: { backgroundColor: 'gray', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 12, name: '12Bogey', style: { backgroundColor: 'gray', color: 'white' }, css: 'hole-shot-doble-bogey' },
    { score: 99, name: 'Retired', style: { backgroundColor: 'black', color: 'white' }, css: 'hole-shot-retired' },
];

export const getScoreData = score => {
    return scoreData.find(sc => sc.score === score);
}

// export const pageTitleContainer = "mt-sm-3 mb-sm-3 ms-sm-3 me-sm-3 mt-md-1 mb-md-1 ms-md-3 me-md-3";
export const pageTitleContainer = "ps-3 pe-3 mb-3";

export const hcpStyle = hcp => ({ color: hcp <= 6 ? 'red' : hcp <= 12 ? 'orange' : 'green', fontWeight: '600', fontSize: '16px' });

export const TeeTypeGolf = 'Golf';
export const TeeTypePAR3 = 'PAR3';
export const TeeTypePitchAndPutt = 'Pitch and Putt';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiamNyb2xkYW4iLCJhIjoiY2t4YzVxMHVuMGV2NjJubW13YmZocDQzYSJ9.AQ1Cn8xY4EMIxioHpqoKlQ'; // Set your mapbox token here

export const playModes = ["Individual", "Pair individual", "Pair Fourball", "Pair Foursome", "Scramble"]

export const scoringModes = ['Stableford', 'Strokes'];

export const zoomPARFactor = teeHole => teeHole.par === 3 ? 0.94 : 1;

export const teeColors = ["red", "blue", "yellow", "white", "black", "green"];
